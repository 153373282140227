var Diode = require('diode');

var NumberStepper = {

  el: '[data-component="number-stepper"]',

  ui: {
    decrementButton: '[data-number-stepper="decrement-button"]',
    incrementButton: '[data-number-stepper="increment-button"]',
    input: '[data-number-stepper="input"]'
  },

  events: {
    'click {decrementButton}': 'decrement',
    'click {incrementButton}': 'increment',
    'change {input}': 'handleChange',
  },

  // These custom events are used to trigger alt button text in:
  // order-summary.js
  customIncrement: new CustomEvent('numberStepperIncrement'),
  customDecrement: new CustomEvent('numberStepperDecrement'),

  initialize: function() {
    Diode(this)
    const value = this.getInputValue();
    const max = parseInt(this.ui.input[0].max, 10) || null;

    const canDecrement = value > 0;
    const canIncrement = max == null || max > value;

    this.state = {
      canDecrement: canDecrement,
      canIncrement: canIncrement,
      max: max,
    }
    const isInsideTicketQuantityForm = Boolean(this.el.closest('[data-component="ticket-number-stepper"]'))
    this.isTicketQuantityChild = !Boolean(this.el.dataset.component) && isInsideTicketQuantityForm
    this.isFullyDisabled = Boolean(this.el.dataset.disabled)
    this.updateState()

    this.ui.decrementButton[0].classList.remove('hide')
    this.ui.incrementButton[0].classList.remove('hide')

    this.render()
  },

  change: function(addend = 0) {
    this.ui.input[0].value = this.getInputValue() + addend

    // programmatically changing a number input does not dispatch its change event
    // we simulate the built-in event
    const event = new InputEvent('change', {
      bubbles: true,
    })
    this.ui.input[0].dispatchEvent(event)
  },

  decrement: function(_e) {
    if (!this.state.canDecrement) {
      return
    }

    this.change(-1)
    window.dispatchEvent(this.customDecrement)
  },

  increment: function(_e) {
    if (!this.state.canIncrement) {
      return
    }

    this.change(1)
    window.dispatchEvent(this.customIncrement)
  },

  render: function() {
    if (this.isFullyDisabled) {
      this.ui.input[0].setAttribute('readonly', 'readonly')
    }
    if (this.state.canDecrement) {
      this.ui.decrementButton[0].removeAttribute('disabled')
      this.ui.decrementButton[0].classList.remove('color-disabled')
      this.ui.decrementButton[0].classList.add('color-link')
    } else {
      this.ui.decrementButton[0].setAttribute('disabled', '')
      this.ui.decrementButton[0].classList.add('color-disabled')
      this.ui.decrementButton[0].classList.remove('color-link')
    }

    if (this.state.canIncrement) {
      this.ui.incrementButton[0].removeAttribute('disabled')
      this.ui.incrementButton[0].classList.remove('color-disabled')
      this.ui.incrementButton[0].classList.add('color-link')
    } else {
      this.ui.incrementButton[0].setAttribute('disabled', '')
      this.ui.incrementButton[0].classList.add('color-disabled')
      this.ui.incrementButton[0].classList.remove('color-link')
    }
  },

  handleChange: function() {
    if (this.isTicketQuantityChild) {
      this.publish()
      return
    }
    this.updateState()
  },

  updateState: function() {
    if (this.isFullyDisabled) {
      this.state.canDecrement = false
      this.state.canIncrement = false
      this.render()
      return
    }
    const value = this.getInputValue()
    this.state.canDecrement = value > 0

    if (this.state.max) {
      this.state.canIncrement = value < this.state.max
    }
    this.render()
  },

  // this method is called from app/javascript/src/components/ticket-number-stepper.js
  // via `Diode.publish`
  _updateStateFromTotalTicketQuantity: function(totalTicketQuantity) {
    const value = this.getInputValue()
    this.state.canIncrement = totalTicketQuantity < this.state.max && value < this.state.max
    this.state.canDecrement = value > 0
    this.render()
  },

  getInputValue: function() {
    return parseInt(this.ui.input[0].value, 10)
  },
}

module.exports = NumberStepper
