var Washi = require('washi');

var throttle = require('lodash.throttle');

const ACTIVE_CLASS = '-active'
const ACTIVE_FACTOR = 3 // Controls how far down the user must scroll before the
                        // pinned nav is revealed, and how far up the user can
                        // scroll before the pinned nav is hidden.
                        // e.g. if 2, pinned nav will be revealed once the user has
                        // scrolled to a point twice the (non-pinned) nav's below the
                        // (non-pinned) nav.
                        // Adjust as necessary for best effect; test with speedy
                        // upwards scroll.

var StickyNav = Washi.$.extend({
  el: '[data-component="sticky-nav"]',

  ui: {
    freeNav:   '[data-sticky-nav="static"]',
    pinnedNav: '[data-sticky-nav="sticky"]'
  },

  initialize: function() {
    this.active = false
    this.header = document.querySelector('[data-sticky-nav="header"]')
    this.ticking = false

    this.bindEvents()
  },

  bindEvents: function() {
    document.addEventListener('scroll', throttle(this.onScroll.bind(this), 50))
  },

  onScroll: function() {
    if (!this.ticking) {
      requestAnimationFrame(this.update.bind(this))
      this.ticking = true
    }
  },

  shouldBeActive: function() {
    let freeNavBounds = this.ui.freeNav[0].getBoundingClientRect()
    let threshold = -ACTIVE_FACTOR * freeNavBounds.height
    let passedThreshold

    if (this.header) {
      passedThreshold = this.header.getBoundingClientRect().bottom < threshold
    } else {
      passedThreshold = freeNavBounds.bottom < threshold
    }

    return passedThreshold
  },

  update: function() {
    this.ticking = false

    if ((!this.active && this.shouldBeActive()) || (this.active && !this.shouldBeActive())) {
      this.ui.pinnedNav[0].classList.toggle(ACTIVE_CLASS)
      this.active = !this.active

      if (this.active) {
        this.hideFromAssistiveSoftware(this.ui.freeNav[0])
        this.revealToAssistiveSoftware(this.ui.pinnedNav[0])
      } else if (!this.active) {
        this.hideFromAssistiveSoftware(this.ui.pinnedNav[0])
        this.revealToAssistiveSoftware(this.ui.freeNav[0])
      }
    }

  },

  hideFromAssistiveSoftware: function(el) {
    el.setAttribute('aria-hidden', true)
  },

  revealToAssistiveSoftware: function(el) {
    el.removeAttribute('aria-hidden')
  }
});

module.exports = StickyNav;
