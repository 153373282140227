var activeClass = '-video-active';
var playerClass = 'page-hero__media__video';
var isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
var prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;

var VimeoVideo = {

  el: '.page-hero[data-video-platform="vimeo"]',

  ui: {
    closeButton : '.page-hero__media__video-close-btn',
    introVideo  : '.page-hero__media__intro-video',
    showButton  : '.page-hero__html__video-show-btn',
    media       : '.page-hero__media'
  },

  events: {
    'click {closeButton}' : 'closeVideo',
    'click {showButton}'  : 'showVideo'
  },

  initialize: function() {
    this.autoplay = this.el.getAttribute('data-video-autoplay');
    this.id = this.el.getAttribute('data-video-id');
    this.privacyHash = this.el.getAttribute('data-video-privacy-hash');
    this.loop = this.el.getAttribute('data-video-loop');

    if (!prefersReducedMotion) {
      if (this.autoplay || isMobile) {
        this.showVideo();
      } else {
        this.showIntroVideo();
      }
    }
  },

  appendPlayer: function() {
    if (!this.getPlayer()) {
      this.ui.$media.get(0).appendChild(this.createPlayer());
    }
  },

  createPlayer: function() {
    var params = '';
    var frame = document.createElement('iframe');

    frame.setAttribute('allow', 'autoplay; fullscreen');
    frame.setAttribute('allowfullscreen', '');
    frame.setAttribute('class', playerClass);
    frame.setAttribute('frameborder', '0');
    frame.setAttribute('height', '100%');
    frame.setAttribute('width', '100%');

    if (this.autoplay || this.loop) {
      params += '&loop=1';
    }

    if (this.autoplay) {
      params += '&autoplay=1&autopause=0';
    }

    frame.setAttribute(
      "src",
      `https://player.vimeo.com/video/${this.id}?background=1&transparent=0&${params}&h=${this.privacyHash}`
    )

    return frame;
  },

  getPlayer: function() {
    return this.el.querySelector(`.${ playerClass }`);
  },

  closeIntroVideo: function(e) {
    var intro = this.ui.$introVideo.get(0);
    intro && intro.classList.add('hide');
  },

  closeVideo: function(e) {
    this.el.classList.remove(activeClass);
    var video = this.getPlayer();
    video.parentNode.removeChild(video);
    this.ui.$showButton.get(0).focus();
    e.stopPropagation();
  },

  showIntroVideo: function(e) {
    var intro = this.ui.$introVideo.get(0);
    if (!intro) { return; }
    intro.classList.remove('hide');
    intro.addEventListener('ended', this.closeIntroVideo.bind(this));
  },

  showVideo: function(e) {
    this.appendPlayer();
    this.el.classList.add(activeClass);
    if (e) {
      this.ui.$closeButton.get(0).focus();
      e.stopPropagation();
    }
    setTimeout(this.closeIntroVideo.bind(this), 500);
  }

};

module.exports = VimeoVideo;
