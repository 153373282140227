/**
 * XHR
 * A generic AJAX wrapper
 */

module.exports = function(method, url, callback) {
  var isXDomain = window.XDomainRequest && url.indexOf('http') === 0;
  var request = isXDomain ? new XDomainRequest() : new XMLHttpRequest();

  request.open(method.toUpperCase(), url, true);

  request.onload = function() {
    if (request.status >= 200 && request.status < 400) {
      callback(request);
    } else {
      throw new Error(`Failed to load resource ${ url }`);
    }
  };

  request.send();
};
