const Washi = require('washi');
const Fixed = require('./mixins/fixed');

const PhotoGallery = Washi.$.extend({
  el: '.photo-gallery',

  ui: {
    button: '.photo-gallery__item__button',
    photos: '.photo-gallery__item__button img',
    modal: '.photo-gallery__modal',
    modalImage: '.photo-gallery__modal__image',
    modalText: '.photo-gallery__modal__text',
    modalAttribution: '.photo-gallery__modal .credit__attribution',
    modalCaption: '.photo-gallery__modal__text__caption',
    next: '[data-next]',
    previous: '[data-previous]',
    close: '[data-close]'
  },

  events: {
    'click {modal}': 'onOuterClick',
    'click {previous}': 'previousSlide',
    'click {next}': 'nextSlide',
    'click {close}': 'toggleModal'
  },

  index : 0,

  initialize() {
    this.slides = this.ui.photos.map(function(img) {
      return {
        url: img.getAttribute('data-image-url'),
        caption: img.getAttribute('data-image-caption') || '',
        attribution: img.getAttribute('data-image-attribution') || ''
      }
    });

    this.ui.button.forEach((el, i) => {
      el.addEventListener('click', () => this.openSlide(i));
    });

    window.addEventListener('keyup', this.onKeyUp.bind(this), true);
  },

  openSlide(index) {
    this.setSlide(index);
    this.toggleModal();
  },

  setSlide(index) {
    this.index = index;
    this.renderModal();
  },

  previousSlide() {
    let previous = this.index - 1;

    if (previous < 0) {
      previous = this.slides.length - 1;
    }

    this.ui.previous.forEach(el => el.focus());

    this.setSlide(previous);
  },

  nextSlide() {
    let next = this.index + 1;

    if (next >= this.slides.length) {
      next = 0;
    }

    this.setSlide(next);

    this.ui.next.forEach(el => el.focus());
  },

  renderModal() {
    const { url, caption, attribution } = this.slides[this.index] || this.slides[0];

    this.ui.modalImage.forEach(el => el.src = url);

    this.ui.modalCaption.forEach(el => el.innerText = caption);

    this.ui.modalAttribution.forEach(el => el.innerText = attribution);

    if (caption || attribution) {
      this.ui.modalText.forEach(el => el.classList.add('-backdrop'))
    } else {
      this.ui.modalText.forEach(el => el.classList.remove('-backdrop'))
    }
  },

  show() {
    const modal = this.ui.modal[0];

    modal.classList.remove('-out');
    modal.classList.toggle('-active');

    clearTimeout(this.animation);
    this.animation = setTimeout(() => modal.classList.add('-in'), 50);

    this.ui.next.forEach(el => el.focus());
  },

  hide() {
    const modal = this.ui.modal[0];

    modal.classList.remove('-in');
    modal.classList.add('-out');

    clearTimeout(this.animation);
    this.animation = setTimeout(() => this.dispose(), 400);
  },

  dispose() {
    this.ui.modal.forEach(el => el.classList.remove("-active"));
    this.ui.modalImage.forEach(el => el.removeAttribute('src'));
    this.ui.modalAttribution.forEach(el => el.innerText = "");

    this.ui.button[this.index].focus();
  },

  toggleModal() {
    this.toggleFixed();

    return this.isFixed ? this.show() : this.hide();
  },

  onKeyUp(e) {
    switch (this.isFixed && e.keyCode) {
      case 32: // space
      case 27: // escape
        e.preventDefault();
        return this.toggleModal();

      case 37: // left arrow
        return this.previousSlide();

      case 39: // right arrow
        return this.nextSlide();
    }
  },

  onOuterClick(e) {
    if (e.target === this.ui.modal[0]) {
      this.toggleModal();
    }
  }
}, Fixed);

module.exports = PhotoGallery;
