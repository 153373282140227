/**
 * Replacement for inline javascript icon/stylesheet loading. Uses the helpers in
 * ApplicationHelper to inject data divs that get loaded during runtime.
 */
import injectIcons from './injectIcons';
import injectStyle from './injectStyle';
import fetch from './fetch';

function loadIcons(namespace, url) {
  fetch(namespace, url, injectIcons);
}

function loadStylesheet(namespace, url) {
  fetch(namespace, url, injectStyle);
}

export default function() {
  var slides = document.getElementsByClassName("loader-div");

  for(var i = 0; i < slides.length; i++) {
    var loaderType = slides[i].dataset.loaderType;
    var loaderUrl = slides[i].dataset.url;
    var loaderNamespace = slides[i].dataset.namespace;

    switch (loaderType) {
      case "stylesheet":
        loadStylesheet(loaderNamespace, loaderUrl);
        break;

      case "icons":
        loadIcons(loaderNamespace, loaderUrl);
        break;

      default:
        break;
    };
  }

}
