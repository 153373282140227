// Reusable helper function to either run a function if the DOM is loaded
// but before images/styles are loaded. Mainly intended to ensure access to
// DOM elements (like the content_tag divs for the loader function)
//
// See:
// https://developer.mozilla.org/en-US/docs/Web/API/Document/DOMContentLoaded_event
//
// From:
// https://stackoverflow.com/questions/799981/document-ready-equivalent-without-jquery

export default function(fn) {
  // see if DOM is already available
  if (document.readyState === "complete" || document.readyState === "interactive") {
    // call on next available tick
    setTimeout(fn, 1);
  } else {
    // fire when the DOM content is loaded
    document.addEventListener("DOMContentLoaded", fn);
  }
}
