var Washi       = require('washi');
var Fixed       = require('./mixins/fixed');
var activeClass = 'open';


var Menu = Washi.$.extend({

  el: '.menu',

  ui: {
    hamburger       : '.hamburger',
    searchButton    : '.search__btn',
    innerStatic     : '.menu__inner--static',
    innerSticky     : '.menu__inner--sticky',
    innerButton     : 'button.menu__nav__inner__btn',
    innerFlyout     : '.menu__nav__inner__flyout',
    nav             : '#menu',
    search          : '#search',
    wcsButtonStatic : '.menu__wcs-button.-static',
    wcsButtonSticky : '.menu__wcs-button.-sticky',
    zoneButton      : '.menu__zone__header__btn',
    zoneNav         : '#menu-zone-links'
  },

  events: {
    'click {hamburger}'       : 'toggleNav',
    'click {searchButton}'    : 'toggleSearch',
    'click {innerButton}'     : 'toggleFlyout',
    'click {wcsButtonStatic}' : 'toggleSitesStatic',
    'click {wcsButtonSticky}' : 'toggleSitesSticky',
    'click {zoneButton}'      : 'toggleZoneNav'
  },

  $openButton: null,
  $openTarget: null,

  initialize: function() {
    this.film = document.querySelector('.film');
    this.film && this.film.addEventListener('click', this.close.bind(this));
    this.positionZoneNav();
  },

  close: function(e) {
    if (e && e.target.classList.contains(activeClass)) {
      this.toggleBody();
    }
    if (this.$openButton && this.$openTarget) {
      this.$openButton.get(0).classList.remove(activeClass);
      this.$openTarget.get(0).classList.remove(activeClass);
      this.$openButton = null;
      this.$openTarget = null;
    }
  },

  closeOpen: function($currentButton, $currentTarget) {
    if (this.$openButton && this.$openButton !== $currentButton) {
      this.close();
      return true;
    }
    return false;
  },

  closeFlyout: function(el) {
    el.removeAttribute('style');
    setTimeout(function() {
      el.classList.remove(activeClass);
    }, 450);
  },

  openFlyout: function(el) {
    var height = el.offsetHeight;
    el.classList.add(activeClass);
    setTimeout(function() {
      el.setAttribute('style', `max-height: ${ height }px;`);
    }, 50);
  },

  positionZoneNav() {
    this.content = document.querySelector('#content');
    var zoneNav = this.ui.$zoneNav.get(0);
    var hasMenuHero = document.querySelectorAll('.menu__hero').length > 0;

    if (window.innerWidth >= 768 && zoneNav && !hasMenuHero) {
      this.content.setAttribute('style', `padding-top: ${ zoneNav.offsetHeight + 1 }px;`);
    }
  },

  toggle: function($button, $target, $focus = true) {
    var button = $button.get(0);
    var target = $target.get(0);

    button.classList.toggle(activeClass);
    if ($focus) {
      button.focus();
    }

    target.classList.toggle(activeClass);

    var isOpen = target.classList.contains(activeClass);
    this.$openButton = isOpen ? $button : null;
    this.$openTarget = isOpen ? $target : null;
  },

  toggleBody: function() {
    this.toggleFixed();
    this.film && this.film.classList.toggle(activeClass);
  },

  toggleFlyout: function(e) {
    var controls = e.target.getAttribute('aria-controls');
    var flyout = document.getElementById(controls);
    var isOpen = flyout.classList.contains(activeClass);

    e.target.classList.toggle(activeClass);

    if (isOpen) {
      this.closeFlyout(flyout);
    } else {
      this.openFlyout(flyout);
    }
  },

  toggleMenu: function($button, $target) {
    var isOpen = this.closeOpen($button, $target);
    this.toggle($button, $target);
    !isOpen && this.toggleBody();
  },

  toggleNav: function(e) {
    this.toggleMenu(this.ui.$hamburger, this.ui.$nav);
    e.stopPropagation();
  },

  toggleSearch: function(e) {
    this.toggleMenu(this.ui.$searchButton, this.ui.$search);
    e.stopPropagation();
  },

  toggleSitesStatic: function(e) {
    this.toggle(this.ui.$wcsButtonStatic, this.ui.$innerStatic);
    this.toggle(this.ui.$wcsButtonSticky, this.ui.$innerSticky, false);
  },

  toggleSitesSticky: function(e) {
    this.toggle(this.ui.$wcsButtonSticky, this.ui.$innerSticky);
    this.toggle(this.ui.$wcsButtonStatic, this.ui.$innerStatic, false);
  },

  toggleZoneNav: function(e) {
    this.ui.$zoneButton.get(0).classList.toggle(activeClass);
    var zoneNav = this.ui.$zoneNav.get(0);

    if (zoneNav.classList.contains(activeClass)) {
      this.closeFlyout(zoneNav);
    } else {
      this.openFlyout(zoneNav);
    }

    e.stopPropagation();
  }

}, Fixed);

module.exports = Menu;
