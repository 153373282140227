var Washi = require('washi');
var NumberStepper = require('./number-stepper');

var TicketNumberStepper = {

  el: '[data-component="ticket-number-stepper"]',

  ui: {
    inputs: '[data-number-stepper="input"]',
    steppers: '[data-ticket-quantity-node]',
  },

  initialize: function() {
    let steppers = []
    Washi(NumberStepper, { el: "[data-ticket-quantity-node]" }).map(n => {
      steppers.push(n)
    })
    this.steppers = steppers
    this.steppers.forEach((stepper) => {
      stepper.listen(this.handleStepperChange, this)
    })
    // Need to call it at initialization in case of a refresh with values already set
    this.handleStepperChange()
  },

  handleStepperChange: function() {
    let totalTicketQuantity = this.steppers.reduce((total, stepper) => {
      let value = parseInt(stepper.ui.input[0].value, 10)
      if (isNaN(value)) {
        console.warn("Expected a numeric value for ticket quantity, received: ", stepper.ui.input[0].value)
        value = 0
      }
      return total + value
    }, 0)
    this.steppers.forEach((stepper) => {
      if (stepper._updateStateFromTotalTicketQuantity && typeof(stepper._updateStateFromTotalTicketQuantity) === "function") {
        stepper._updateStateFromTotalTicketQuantity(totalTicketQuantity)
      } else {
        console.warn("Expected a function named _updateStateFromTotalTicketQuantity on NumberStepper, received: ", stepper)
      }
    })
  },
}

module.exports = TicketNumberStepper
