/**
 * Fetch
 * Retrieve a resource from a given `url`, then save it to a `key` in
 * localStorage. If the key already exists, only pull from localStorage
 */

var hasLocalStorage = require('./hasLocalStorage');
var xhr             = require('./xhr');


/**
 * Given a `prefix`, remove all keys from localStorage that include
 * that prefix.
 */

function removeItemByPrefix(prefix) {
  var pattern = new RegExp(`^${ prefix }_`);

  Object.keys(localStorage)
        .filter(key => pattern.test(key))
        .forEach(key => localStorage.removeItem(key))
}


export default function(key, url, callback) {
  var ref = key + '_' + url;

  if (hasLocalStorage && ref in localStorage) {
    return callback(localStorage.getItem(ref));
  }

  xhr('GET', url, function({ responseText }) {
    if (hasLocalStorage) {
      removeItemByPrefix(key);
      localStorage[ref] = responseText;
    }

    callback(responseText);
  });
};
