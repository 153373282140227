

import sentry from './utils/sentry.js'

if (window.Sentry === undefined) {
  window.Sentry = window.Sentry || sentry.Sentry

  sentry.installSentry({
    environment  : 'production',
    release      : 'e88c6c480',
    ignoreErrors: [
      // https://github.com/wildlife-conservation-society/wcs/issues/2191
      /twttr.*defined/,

      // https://github.com/wildlife-conservation-society/wcs/issues/2367
      /undefined is not an object \(evaluating '__gCrWeb\.autofill\.extractForms'\)/,
      /Blocked a frame with origin.*from accessing a (cross-origin frame|frame with origin)/,

      // https://github.com/wildlife-conservation-society/wcs/issues/2185
      /null is not an object \(evaluating 'elt\.parentNode'\)/,

      // https://github.com/es-shims/es5-shim/issues/249
      /Exception thrown and not caught/,

      // https://github.com/wildlife-conservation-society/wcs/issues/2403
      /Can't find variable: inf/,

      // https://github.com/wildlife-conservation-society/wcs/issues/2501
      /Access is denied\./,

      // https://github.com/wildlife-conservation-society/wcs/issues/2671
      /androidInterface is not defined/,

      // https://github.com/wildlife-conservation-society/wcs/issues/5198
      /undefined is not an object \(evaluating 'this.ui.form\[0\].submit'\)/,
      /undefined is not an object \(evaluating 'buttonsNav.children'\)/,
      /Cannot read properties of undefined \(reading 'submit'\)/,
      /undefined is not an object \(evaluating 'this.ui.submit\[0\].disabled=!0'\)/,
      /Cannot set properties of undefined \(setting 'disabled'\)/,
      /Cannot read property 'children' of undefined/,
      /Cannot read properties of undefined \(reading 'children'\)/,
      /Cannot read properties of undefined \(reading 'Linker'\)/,
      /undefined is not an object \(evaluating 'new window.gaplugins.Linker'\)/,
      /Cannot read property 'length' of undefined/,
      /this.hostIndex.push is not a function/,
      /The string did not match the expected pattern./,
      /undefined is not an object \(evaluating 'n.x'\)/,
      /userInput is not defined/,
      /Can't find variable: userInput/,
      /Cannot read properties of null \(reading 'parentNode'\)/,
      /Cannot read properties of null \(reading 'removeChild'\)/,
      /UnknownError/,
      /Your focus-trap must have at least one container with at least one tabbable node in it at all times/,
      /Cannot read properties of null \(reading 'contentWindow'\)/,
      /buttonsNav is undefined/,
      /DOM Exception 12: An invalid or illegal string was specified./,
      /null is not an object \(evaluating 't.addEventListener'\)/,
      /Cannot read properties of null \(reading 'style'\)/,
      /Cannot read properties of null \(reading 'contentWindow'\)/,
      /Permission denied to access property "call"/,
      /Permission denied to access property "dispatchEvent" on cross-origin object/,
      /DOM Exception 22: An attempt was made to add something to storage that exceeded the quota./,
      /null is not an object \(evaluating 'e.parentNode'\)/,
      /The quota has been exceeded./,
      /Cannot read properties of null \(reading 'classList'\)/,
      /Maximum call stack size exceeded./,
      /AudioEye is not defined/,
      /xiFrame is null/,
      /null is not an object \(evaluating 'xiFrame.contentWindow'\)/,
      /Unable to get property 'addEventListener' of undefined or null reference/,
      /Cannot read properties of undefined \(reading 'planRemediations'\)/,
      /Cannot read property 'toString' of undefined/
    ]
  })
}

