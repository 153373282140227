/*
 * Fixed
 *
 * A component for fixing and unfixing body content (position: fixed;)
 * Useful for showing a modal or similar while maintaining current scroll position
 */

const FIXED_CLASSNAMES = [
  'fixed',
  'position-full'
]

var Fixed = {
  isFixed: false,
  scrollTop: 0,

  getScrollTop: function() {
    return document.body.scrollTop || document.documentElement.scrollTop;
  },

  getFixedClassNames: function() {
    let classNames = this.fixedClassNames
    let isDefined = classNames !== undefined;
    let isEmpty = (
      classNames === null
      || (isDefined && classNames.length && classNames.length === 0)
    )

    if (isEmpty) {
      return [];
    } else if (isDefined) {
      return [].concat(classNames);
    }
    return FIXED_CLASSNAMES;
  },

  toggleFixed: function() {
    if (!this.isFixed) {
      this.scrollTop = this.getScrollTop();
    }

    document.documentElement.classList.toggle('body-fixed');

    let fixedClassNames = this.getFixedClassNames();
    let classListMethod = this.isFixed ? 'remove' : 'add';
    try {
      document.body.classList[classListMethod](...fixedClassNames);
    } catch (e) {
      console && console.error && console.error(e);
    }

    if (!this.isFixed) {
      document.body.setAttribute('style', `top: -${ this.scrollTop }px;`);
    } else {
      window.scrollTo(0, this.scrollTop);
      document.body.removeAttribute('style');
    }

    this.isFixed = !this.isFixed;
  }
};

module.exports = Fixed;
