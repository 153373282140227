const Washi = require('washi');
const Modal = require('./mixins/modal');
const Cookies = require('cookies-js');
const modalCookie = 'banner-promotion-modal';

const BannerPromotionModal = Washi.$.extend({

  el: '#banner-promotion-modal',

  ui: {
    close: '[data-close]',
    forceShow: '[data-force-show]'
  },

  events: {
    'click {close}': 'hide'
  },

  /**
   * See #1928 for more implementation details
   */
  initialize() {
    // Don't open on mobile load per https://github.com/wildlife-conservation-society/wcs/issues/2333
    // Don't open on load unless data-force-show element is present
    if (window.innerWidth >= 768 && this.ui.forceShow.length > 0) {
      this.onShow();
    }

    this.el.addEventListener('forceShowBannerPromotionModal', this.onShow.bind(this, true));
  },

  isSet(cookie) {
    return Cookies.get(cookie) === '1';
  },

  shouldShow(force) {
    return force === true || Cookies.get(modalCookie) !== '1';
  },

  onShow(force) {
    this.tryShow(() => this.shouldShow(force), () => {
      Cookies.set(modalCookie, '1', {
        expires: 86400 // 1 day
      });
    });
  }

}, Modal);

module.exports = BannerPromotionModal;
