var Siema = require('siema');

var Carousel = {
  el          : '[data-component="carousel"]',
  slide       : 0,
  activeClass : 'fill-canary',

  ui: {
    slides  : '[data-carousel-slides]',
    buttons : '[data-carousel-control]',
  },

  initialize: function() {
    let { autoAdvance, mobileCarousel } = this.el.dataset;

    this.options = {
      selector: this.ui.slides[0],
      duration: 600,
      easing: "ease-in-out",
      loop: true,
      multipleDrag: false
    };

    if (this.ui.buttons.length) {
      this.options.onInit   = this.initButtons.bind(this);
      this.options.onChange = this.setNewButton.bind(this);
    }

    if (mobileCarousel) {
      this.initMobileCarousel();
    } else {
      this.Carousel = new Siema(this.options);
    }

    if (autoAdvance) {
      this.timerId = setInterval(() => this.Carousel.next(), 6000)
    }
  },

  initMobileCarousel: function() {
    const desktopSize = window.matchMedia('(min-width: 768px)');

    if (!desktopSize.matches) {
      this.Carousel = new Siema(this.options);
    }

    desktopSize.addListener(this.resetCarousel.bind(this));
  },

  resetCarousel: function(width) {
    if (width.matches) {
      this.Carousel.destroy(true);
    } else {
      if (this.Carousel) {
        this.Carousel.init();
      } else {
        this.Carousel = new Siema(this.options);
      }
    }
  },

  initButtons: function() {
    this.ui.buttons.forEach((btn, i) => {
      // add active class to first button
      if (i === 0) {
        btn.classList.add(this.activeClass);
      }

      btn.addEventListener('click', () => {
        clearInterval(this.timerId)

        this.Carousel.goTo(i)
      })
    })
  },

  setNewButton: function() {
    // remove active class from current button
    this.ui.buttons[this.slide].classList.remove(this.activeClass);

    // add active class to new button
    let n = this.Carousel.currentSlide;
    this.slide = n;
    this.ui.buttons[n].classList.add(this.activeClass);
  },
};

module.exports = Carousel;
