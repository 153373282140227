/**
 * injectStyle
 * Given a string of `content`, inject the content within a
 * style element into the head of the current document.
 */

export default function(content) {
  var style = document.createElement('style');

  style.rel = 'stylesheet';
  style.type = 'text/css';

  document.head.appendChild(style);

  if (style.styleSheet) {
    style.styleSheet.cssText = content; // IE
  } else {
    style.appendChild(document.createTextNode(content));
  }
};
