const ListEventTicketForm = {
  ui: {
    eventTypeSelect: '[data-event-type]',
    guestSelect: '[data-guests]',
    form: '[data-event-list-form]'
  },

  events: {
    'change {eventTypeSelect}': 'submitForm',
    'change {guestSelect}': 'submitForm',
  },

  submitForm() {
    this.ui.form[0].submit();
  }
}

module.exports = ListEventTicketForm;
