var activeClass = '-video-active';
var playerClass = 'page-hero__media__video';
var isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
var prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
var youtubeApiSrc = 'https://www.youtube.com/player_api';

var YoutubeVideo = {

  el: '.page-hero[data-video-platform="youtube"]',

  ui: {
    closeButton : '.page-hero__media__video-close-btn',
    introVideo  : '.page-hero__media__intro-video',
    showButton  : '.page-hero__html__video-show-btn',
    media       : '.page-hero__media'
  },

  events: {
    'click {closeButton}' : 'closeVideo',
    'click {showButton}'  : 'showVideo'
  },

  initialize: function() {
    this.autoplay = this.el.getAttribute('data-video-autoplay');
    this.videoId = this.el.getAttribute('data-video-id');
    this.loop = this.el.getAttribute('data-video-loop');
    this.player = null;

    this.playerElId = `player-${this.videoId}`

    this.createPlayerEl()

    if (!prefersReducedMotion && !isMobile) {
      if (this.autoplay) {
        this.showVideo();
      } else {
        this.showIntroVideo();
      }
    }
  },

  createPlayerEl: function() {
    var tag = document.createElement('div');
    tag.classList.add(playerClass)
    tag.id = this.playerElId
    this.ui.$media.get(0).appendChild(tag)
  },

  appendPlayer: function() {
    var _this = this

    if (window.YT) {
      _this.createPlayer()
    } else {
      if (!document.querySelector(`script[src="${youtubeApiSrc}"]`)) {
        var tag = document.createElement('script');
        var firstScriptTag = document.getElementsByTagName('script')[0];

        tag.src = youtubeApiSrc;

        if (firstScriptTag) {
          firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        } else {
          document.documentElement.insertAdjacentElement('afterbegin', tag);
        }
      }

      window.onYouTubePlayerAPIReady = function() {
        _this.createPlayer()
      }
    }
  },

  createPlayer: function() {
    var playerVars = {
      'autoplay': Number(this.autoplay || this.loop),
      'controls': 0,
      'enablejsapi': 1,
      'loop': Number(this.loop),
      'modestbranding': 1,
      'playsinline': 1,
      'showinfo': 0,
    }

    if (this.loop) {
      playerVars['playlist'] = this.videoId;
    }

    var config = {
      allowfullscreen: '',
      frameborder: 0,
      height: "100%",
      width: "100%",
      videoId: window.youTubeVideoId,
      playerVars: playerVars,
      events: {
        onReady: this.onReady,
        onStateChange: this.onStateChange.bind(this),
      }
    }

    this.player = new YT.Player(this.playerElId, config);
  },

  onReady: function({ target: player }) {
    player.mute();
    player.playVideo();
  },

  onStateChange: function({ data }) {
    if (data === YT.PlayerState.PLAYING) {
      // keep video hidden until it is playing to prevent flash of thumbnail
      this.el.classList.add(activeClass)
      this.ui.$closeButton.get(0).focus();
    } else if (data === YT.PlayerState.ENDED) {
      // remove video when finished to prevent YouTube from showing "related videos"
      this.closeVideo()
    }
  },

  closeIntroVideo: function(e) {
    var intro = this.ui.$introVideo.get(0);
    intro && intro.classList.add('hide');
  },

  closeVideo: function(e) {
    this.el.classList.remove(activeClass);
    this.player.destroy();
    this.ui.$showButton.get(0).focus();

    if (e) {
      e.stopPropagation();
    }

    this.createPlayerEl();
  },

  showIntroVideo: function(e) {
    var intro = this.ui.$introVideo.get(0);
    if (!intro) { return; }
    intro.classList.remove('hide');
    intro.addEventListener('ended', this.closeIntroVideo.bind(this));
  },

  showVideo: function(e) {
    this.appendPlayer();

    if (e) {
      e.stopPropagation();
    }

    setTimeout(this.closeIntroVideo.bind(this), 500);
  }

};

module.exports = YoutubeVideo;
