const Washi = require('washi');
const Modal = require('./mixins/modal');

const InteractionModal = Washi.$.extend({

  el: '[data-component="interaction-modal"]',

  ui: {
    modal:      '[data-interaction-modal="modal"]',
    showButton: '[data-interaction-modal="show"]',
    hideButton: '[data-interaction-modal="hide"]',
    content:    '[data-interaction-modal="content"]',
  },

  events: {
    'click {showButton}': 'showChildModal',
    'click {modal}': 'handleModalClick',
    'click {hideButton}': 'handleHideButtonClick',
  },

  initialize: function() {
    this.el = this.ui.modal[0]
    this.hideFocusEl = this.ui.showButton[0]
    this.scrollEl = this.ui.content[0]

    // allows triggering the modal from an external source #5840
    // requires a unique ID to be passed to bind the instances together
    if (this.el.dataset.interactionModalExternal) {
      const id = this.el.dataset.interactionModalExternal;
      this.externalTrigger = document.querySelector(`${id}[data-interaction-modal="external-trigger"]`)
      this.externalTrigger.addEventListener('click', this.showChildModal.bind(this))
    }
  },

  showChildModal: function() {
    this.show()
  },

  handleHideButtonClick: function(e) {
    this.hide(e)
  },

  handleModalClick: function(e) {
    let content = this.ui.content[0]
    let contentClicked = e.target == content || content.contains(e.target)

    if (!contentClicked) {
      this.hide(e)
    }
  }
}, Modal);

module.exports = InteractionModal;
