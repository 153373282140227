// This replaces the legacy inline javacript loader
import injectAll from "./loader/loader"
import docReady from "./utils/docready"

// make sure we inject after DOMContentLoaded
docReady( injectAll() )

//  polyfill classList, but especially classlist.add and classList.remove for IE10 and IE11
//= require classlist

// polyfill position:sticky. use with .position-sticky class
import Stickyfill from 'stickyfilljs'
var stickyEls = document.querySelectorAll('.position-sticky');
Stickyfill.add(stickyEls);

require('console-polyfill');

// polyfill smoothscroll
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

// Permanent Site Components
var Washi = require('washi');
var GALinkDecorator = require('./components/ga-link-decorator');
var Menu = require('./components/menu');
var Togglable = require('./components/togglable');
var SkipLink = require('./components/skip-link');
var EmailSignup = require('./components/email-signup');
var PhotoGallery = require('./components/photo-gallery');
var VimeoVideo = require('./components/vimeo-video');
var YoutubeVideo = require('./components/youtube-video');
var Contribute = require('./components/contribute');
var Popup = require('./components/popup');
var InteractionModal = require('./components/interaction-modal');
var BannerPromotionModal = require('./components/banner-promotion-modal');
var BannerPromotion = require('./components/banner-promotion');
var EventTicketList = require('./ecomm/views/event-ticket-list');
var BasicTabs = require('./components/basic-tabs');
var Binder = require('./components/binder');
var NumberStepper = require('./components/number-stepper');
var TicketNumberStepper = require('./components/ticket-number-stepper');
var HideShow = require('./components/hide-show');
var AmbientVideo = require('./components/ambient-video');

Washi(GALinkDecorator);
Washi(Menu);
Washi(Togglable);
SkipLink();
Washi(EmailSignup);
Washi(PhotoGallery);
Washi(VimeoVideo);
Washi(YoutubeVideo);
Washi(Contribute);
Washi(Popup);
Washi(InteractionModal);
Washi(BannerPromotionModal);
Washi(BannerPromotion);
Washi(EventTicketList);
Washi(BasicTabs);
Washi(Binder);
Washi(NumberStepper);
Washi(TicketNumberStepper);
Washi(HideShow);
Washi(AmbientVideo);

require('what-input');
require('./sentry');


/**
 * New Homepage Blocks
 *
 */
var Carousel  = require('./components/carousel');
var TVBlock   = require('./components/tv-block');
var StickyNav = require('./components/sticky-nav');

Washi(Carousel);
Washi(TVBlock);
Washi(StickyNav);


/**
 * In-Park App Promo
 */
// var InParkAppPromo = require('./components/in-park-app-promo-modal');
// Washi(InParkAppPromo);

/**
 * Donation Modal Campaign
 * aka Patrons Lightbox
 *
 * This script has an accompanying view partial that must be un/commented out
 * in views/layouts/application.html.erb:
 *
 * render 'shared/donation_modal'
 */
// var DonationModal = require('./components/donation-modal');
// Washi(DonationModal);

/**
 * Advocacy Modal Campaign
 *
 * This script has an accompanying view partial that should be
 * added to views/layouts/application.html.erb:
 * render 'shared/advocacy_modal'
 */
// var AdvocacyModal = require('./components/advocacy-modal');
// Washi(AdvocacyModal);
