const PATHNAMES_ALL_SITES = [
  '/admission-tickets',
  '/buy-tickets',
  '/gifts',
  '/limited-admission-tickets',
  '/member-tickets',
  '/membership_lookup',
  '/memberships',
  '/tickets',
  '/wednesday-tickets'
]

const PATHNAMES_BRONX_ZOO = [
  '/holiday-lights-tickets',
  '/meet-and-greet-tickets',
]

const PATHNAMES_BRONX_ZOO_TREETOP = [
  '/holiday-lights-tickets',
  '/meet-and-greet-tickets',
  '/buy-annual-pass',
  '/renew-annual-pass',
  '/tta-tickets',
]

const normalizePathname = (pathname) => pathname.replace(/\/$/, '')

const { hostname } = window.location

const isDebug = hostname.indexOf('.test') !== -1 || hostname.indexOf('vigetx.com') !== -1

let pathnames = PATHNAMES_ALL_SITES.map(p => normalizePathname(p))

if (hostname === 'bronxzoo.com') {
  pathnames = [
    ...pathnames,
    ...PATHNAMES_BRONX_ZOO.map(p => normalizePathname(p)),
  ]
} else if (hostname === 'bronxzootreetop.com') {
  pathnames = [
    ...pathnames,
    ...PATHNAMES_BRONX_ZOO_TREETOP.map(p => normalizePathname(p)),
  ]
}

const GALinkDecorator = {
  el: 'body',

  ui: {
    allLinkEls: 'a[href]',
  },

  initialize() {
    const linkEls = this.ui.allLinkEls
      .filter(({ href }) => {
        const { pathname } = new URL(href)
        return pathnames.includes(normalizePathname(pathname))
      })

    this.log('Links to decorate:')
    this.log(linkEls)

    linkEls.forEach((linkEl) => {
      linkEl.addEventListener('click', this.handleLinkClick.bind(this))
    })
  },

  decoratedUrl(href) {
    const { gaClientId, gaSessionId } = window
    const { pathname, search } = new URL(href)
    const searchParams = new URLSearchParams(search)

    if (gaClientId) {
      searchParams.append('client_id', gaClientId)
    }

    if (gaSessionId) {
      searchParams.append('session_id', gaSessionId)
    }

    let url = `${pathname}?${searchParams.toString()}`

    // GA 3
    const gobj = window[window['GoogleAnalyticsObject']]
    if (gobj && gobj.getAll) {
      const tracker = gobj.getAll()[0]
      if (window.gaplugins) {
        url = (new window.gaplugins.Linker(tracker)).decorate(url)
      }
    }

    return url
  },

  handleLinkClick(e) {
    e.preventDefault()
    const { currentTarget } = e
    const decoratedUrl = this.decoratedUrl(currentTarget.href)
    this.log(`Navigating to ${decoratedUrl}`)
    window.location.href = decoratedUrl
  },

  log(message) {
    isDebug && console.log(message)
  }
}

module.exports = GALinkDecorator
