var inViewport = require('in-viewport');

var TVBlock = {
  el: '[data-component="tv-block"]',

  initialize: function() {
    var offset = -this.el.offsetHeight / 2

    this.watcher = inViewport(this.el, { offset: offset, debounce: 100 }, () => {
      this.el.classList.add('-turn-on')
    })
  }
};

module.exports = TVBlock;
