// HIDE/SHOW
// Renders a button that accepts:
// - custom css classes
// - a target selector with a required data-hideshow="target" attribute
// - custom button text
// - an optional placeholder element that gets swapped out when the target
//   becomes active
// - an optional validity message to display if the placeholder is a button in
//   a form (see #5681 and #5736)
// Additional notes on issue #5736
//
// TODO: Possible refactor into two-stage calendar implementation. See PR 5711.
// (Especially if this is not used outside of show_templates/_calendar.html.erb)

const HideShow =  {
  el: '[data-component="hideshow"]',

  ui: {
    trigger: '[data-hideshow="trigger"]',
  },

  events: {
    'click {trigger}': 'handleTriggerClick',
  },

  initialize: function() {
    // grab first-instance of target (expects unique selectors)
    this.target = this.el.getAttribute('data-hideshow-selector')
    this.targetEl = document.querySelector(this.target);

    // if there's a placeholder, grab it too
    this.placeholder = this.el.getAttribute('data-hideshow-placeholder') || false;
    this.placeholderEl = document.querySelector(this.placeholder)

    // check for custom validity messages
    this.validity = this.el.getAttribute('data-hideshow-validity') || false;

    // if both exist, set a custom blocking message
    if (this.placeholder && this.validity) {
      this.placeholderEl.setCustomValidity(this.validity)
    }
  },

  resetButtons: function() {
    // side effect: reset all all hideshow targets
    let targets = document.querySelectorAll('[data-hideshow="target"]');
    targets.forEach((button) => {
      button.classList.add('hide')
    })

    // side effect: also reset all the trigger buttons
    let toggleButtons = document.querySelectorAll('[data-hideshow="trigger"]')
    toggleButtons.forEach((button) => {
      button.ariaHidden = true
      button.ariaChecked = false
    })
  },

  handleTriggerClick: function() {
    this.placeholderEl ? this.placeholderEl.classList.add('hide') : null;
    this.resetButtons()
    this.targetEl.classList.remove('hide')
    this.el.ariaHidden = false
    this.el.ariaChecked = true
  }
}

module.exports = HideShow
