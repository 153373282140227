/**
 * injectIcons
 * Given a string of svg `content`, inject the content within a
 * hidden div into the body of the current document.
 */

export default function(svg) {
  var div = document.createElement('div');

  div.className     = 'wcs_icon_loader';
  div.style.display = 'none';
  div.innerHTML     = svg;

  document.body.appendChild(div);
};
