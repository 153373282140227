var Togglable = {

  el: '.togglable',

  ui: {
    content: '.togglable__content',
    btn: '.togglable__btn'
  },

  events: {
    'click {btn}': 'toggle'
  },

  toggle(e) {
    e.preventDefault();

    this.ui.$btn.get(0).classList.toggle('open');
    this.ui.$btn.get(0).focus();

    this.ui.$content.get(0).classList.toggle('open');
  }

};

module.exports = Togglable;
